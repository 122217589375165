import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "What are the top challenges organizations face during software product development?",
    "What are the main challenges in coordinating and collaborating with different roles and teams during product development?",
    "What emerging trends in AI and SDLC automation are customers most excited about?"
];

const GPT4V_EXAMPLES: string[] = [
    "What are the top challenges organizations face during software product development?",
    "What collaboration tools or platforms do teams currently use, and what limitations do they have?",
    "What emerging trends in AI and SDLC automation are customers most excited about?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
